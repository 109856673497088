div.zoomToFit {
  background: none rgb(255, 255, 255);
  border: 0px;
  margin: 10px;
  padding: 0px;
  cursor: pointer;
  user-select: none;
  border-radius: 2px;
  height: 40px;
  width: 40px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

div.zoomToFit span {
  color: rgba(0, 0, 0, 0.54);
  position: relative;
  user-select: none;
  font-size: 28px;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  padding: 0px;
  margin: auto;
  font-weight: 300;
}

div.zoomToFit span:hover {
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
}
