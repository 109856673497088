* {
  min-height: 0;
  min-width: 0;
}

html,
body {
  width: 100%;
  height: 100%;
}

#root {
  width: 100%;
  height: 100%;
}

body {
  font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: normal;
}

#render-target {
  margin: 0 auto;
  width: 100%;
  height: 100%;
}
